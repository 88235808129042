import { request } from "./APIUtils"

export const TT = { method: 'POST', url: process.env.REACT_APP_BASE_URL + 'tt' }
export const CHECK_TOKEN = { method: 'GET', url: process.env.REACT_APP_BASE_URL + 'immotechuser/check-token' }
export const NEXT_EVENT = { method: 'GET', url: process.env.REACT_APP_BASE_URL + 'immotech/next-event' }
export const EVENT_YEARS = { method: 'GET', url: process.env.REACT_APP_BASE_URL + 'immotech/event-years' }
export const GALLERY_YEARS = { method: 'GET', url: process.env.REACT_APP_BASE_URL + 'immotech/gallery-years' }
export const VIDEOS_YEARS = { method: 'GET', url: process.env.REACT_APP_BASE_URL + 'immotech/videos-years' }
export const GET_BILLETERIE_OPTIONS = { method: 'GET', url: process.env.REACT_APP_BASE_URL + 'immotech/billeterie-options' }
export const GET_RATE_OPTIONS = { method: 'GET', url: process.env.REACT_APP_BASE_URL + 'immotechprice/price-options' }
export const CHECK_PROMOTION_CODE = { method: 'POST', url: process.env.REACT_APP_BASE_URL + 'immotechpromotioncode/check-promotion-code' }
export const REGISTER_PAYMENT = { method: 'POST', url: process.env.REACT_APP_BASE_URL + 'immotechpayment/register-payment' }
export const GET_PAYMENT = { method: 'GET', url: process.env.REACT_APP_BASE_URL + 'immotechpayment/get-payment' }
export const PREPARE_PAYMENT = { method: 'POST', url: process.env.REACT_APP_BASE_URL + 'immotechpayment/prepare-payment' }
export const GET_PAYMENT_RESPONSE = { method: 'GET', url: process.env.REACT_APP_BASE_URL + 'immotechpayment/get-payment-response' }
export const LOGIN = { method: 'POST', url: process.env.REACT_APP_BASE_URL + 'immotechuser/login' }
export const FORGOT_PASSWORD = { method: 'POST', url: process.env.REACT_APP_BASE_URL + 'immotechuser/forgot-password' }
export const RESET_PASSWORD = { method: 'POST', url: process.env.REACT_APP_BASE_URL + 'immotechuser/reset-password' }
export const GET_ENTRANCE = { method: 'GET', url: process.env.REACT_APP_BASE_URL + 'immotechentrance/get-entrance' }
export const SCANNED_ENTRANCE_LIST_FILTERS = { method: 'GET', url: process.env.REACT_APP_BASE_URL + 'immotechentrance/scanned-entrance-list-filters' }
export const GET_SCANNED_ENTRANCE_LIST = { method: 'GET', url: process.env.REACT_APP_BASE_URL + 'immotechentrance/get-scanned-entrance-list' }
export const LOGOUT = { method: 'POST', url: process.env.REACT_APP_BASE_URL + 'immotechuser/logout' }
export const GALLERY = { method: 'GET', url: process.env.REACT_APP_BASE_URL + 'immotech/event-gallery' }
export const GALLERY_VIDEO = { method: 'GET', url: process.env.REACT_APP_BASE_URL + 'immotech/event-videos' }
export const SAVE_EXPOSANT = { method: 'POST', url: process.env.REACT_APP_BASE_URL + 'immotechexposant/request' }
// Workshops
export const GET_WORKSHOPS = { method: 'GET', url: process.env.REACT_APP_BASE_URL + 'immotechworkshop/event/{eventId}' }
export const GET_WORKSHOP = { method: 'GET', url: process.env.REACT_APP_BASE_URL + 'immotechworkshop/{workshopName}' }
export const GET_SECTOR_OPTIONS = { method: 'GET', url: process.env.REACT_APP_BASE_URL + 'immotech/sector-options' }
export const GET_ENTRANCES_BY_EMAIL = { method: 'GET', url: process.env.REACT_APP_BASE_URL + 'immotechentrance/find-by-email' }
// Emails
export const GET_EMAILS = { method: 'GET', url: process.env.REACT_APP_BASE_URL + 'emails' }
export const PREVIEW_EMAIL = { method: 'GET', url: process.env.REACT_APP_BASE_URL + 'emails/{id}/preview' }
export const SAVE_EMAIL = { method: 'PUT', url: process.env.REACT_APP_BASE_URL + 'emails/{id}' }
// Entrances list
export const ENTRANCE_LIST_FILTERS = { method: 'GET', url: process.env.REACT_APP_BASE_URL + 'immotechentrance/entrance-list-filters' }
export const GET_ENTRANCE_LIST = { method: 'GET', url: process.env.REACT_APP_BASE_URL + 'immotechentrance/get-entrance-list' }
export const RESEND_ENTRANCE = { method: 'POST', url: process.env.REACT_APP_BASE_URL + 'immotechentrance/resend-entrance' }
export const EXPORT_ENTRANCES = { method: 'GET', url: process.env.REACT_APP_BASE_URL + 'immotechentrance/entrance-export' }
// Exposants list
export const GET_EXPOSANTS_LIST = { method: 'GET', url: process.env.REACT_APP_BASE_URL + 'immotechexposant/get-exposant-list' }
export const CREATE_EXPOSANT = { method: 'POST', url: process.env.REACT_APP_BASE_URL + 'immotechexposant' }
export const GET_PROMOTION_CODES = { method: 'GET', url: process.env.REACT_APP_BASE_URL + 'immotechpromotioncode' }

export function getUrlParameter(sParam) {
    const sPageURL = window.location.search.substring(1);
    const sURLVariables = sPageURL.split('&');

    for (let index = 0; index < sURLVariables.length; index++) {
        let sParameterName = sURLVariables[index].substring(0, sURLVariables[index].indexOf('='));
        let sParameterValue = sURLVariables[index].substring(sURLVariables[index].indexOf('=') + 1);

        if (sParameterName === sParam) {
            return sParameterValue === undefined ? true : sParameterValue;
        }
    }
    return false;
}

export async function getEventId() {
    if (document.cookie.indexOf('_eId=') < 0) {
        return request(NEXT_EVENT).then(async (jsonObject) => {
            document.cookie = '_eId=' + jsonObject.event.id;
            return jsonObject.event.id;
        })
    } else {
        let eventId = document.cookie.substring(document.cookie.indexOf('_eId=') + '_eId='.length);
        if (eventId != null && eventId.indexOf(';') > 0) {
            eventId = eventId.substring(0, eventId.indexOf(';'))
        }
        return eventId;
    }
}